export const SIDEBAROPEN = '@sidebaropen';
export const SIDEBARSHOW = '@sidebarshow';

export const MONTH = {
  1: 'Janeiro',
  2: 'Fevereiro',
  3: 'Março',
  4: 'Abril',
  5: 'Maio',
  6: 'Junho',
  7: 'Julho',
  8: 'Agosto',
  9: 'Setembro',
  10: 'Outubro',
  11: 'Novembro',
  12: 'Dezembro',
};

export const SITUACAO = {
  'a-vencer': 'A vencer',
  pago: 'Pago',
  vencido: 'Vencido',
};
